.BacklogOverview {
  margin-top: 12rem;
  padding: 1rem;
  width: 100%;

  &--InfoContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }
}

.BacklogOverItemsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 30rem));
  gap: 1rem; /* Adjust the gap between items as needed */
  padding: 0.5rem;
  justify-content: center;
}

fieldset {
  .BacklogOverItemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 30rem));
    gap: 1rem; /* Adjust the gap between items as needed */
    padding: 0.5rem;
    justify-content: center;
  }
}

.UserRating {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 40% 20%;
  grid-template-rows: 1fr 3fr 1fr;
  height: 45%;
  gap: 5%;

  textarea {
    height: 100%;
    resize: none;
    padding: 1rem 1rem 1rem 1rem;
    transition: all 0.1s;
    grid-column: 2/-1;
  }

  .shareContainer {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5%;
    row-gap: 10%;
  }

  .commentLabel {
    grid-row: 2;
    grid-column: 1;
  }
}

// #########################
//  BREAKPOINTS
//##########################
$bp-largest: 121.25em; //1940:16
$bp-larger: 60em; //1440:16
$bp-large: 75em; //1200px:16
$bp-medium: 50em; //800px:16
$bp-small: 31.25em; //500px:16
$bp-smallest: 24.38em; //390px:16

$fontcolor: rgb(55 65 81);

$colorMain: #512da8;
$colorSecondary: #9c51e1;
// COLOR SECONDARY : 673ab7

$gradientBackground: linear-gradient(to right, #9c51e1, #512da8);

$shadow: 0 1rem 2rem rgba(92, 22, 197, 0.2);

$borderRadius: 12px;
$border: 2px solid #edeeee;

// @import url("https://fonts.googleapis.com/css2?family=Codystar&display=swap");
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Nunito Sans", sans-serif;

  @media only screen and (max-width: $bp-large) {
    font-size: 50%; // equal to 8 px
  }
  @media only screen and (max-width: $bp-smallest) {
    font-size: 40%; // equal to  6.4 px
  }
}

body {
  min-height: 100vh;
  color: $fontcolor;
  background-color: var(--color-darkest);
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.btn-primary,
.btn-primary--inverted,
.btn-primary--semiInverted,
.btn-disabled,
.btn-delete {
  border: 2px solid white;
  height: 3rem;
  width: 6rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  text-decoration: none;
}

.btn-primary,
.btn-primary--inverted,
.btn-primary--semiInverted,
.btn-delete {
  &:hover {
    // background-color: $color-main--hover;
    box-shadow: $shadow;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(-1px);
  }
}

.btn-primary {
  background-color: #5c16c5;
  color: white;
}

.btn-primary--inverted {
  background-color: transparent;
  color: white;
}

.btn-primary--semiInverted {
  background-color: transparent;
  color: #5c16c5;
}

.btn-disabled {
  background-color: #f5f5f5;
  color: #5c16c5;
}

.btn-delete {
  background-color: #ff6874;
  color: white;
}

h1 {
  font-weight: 900;
}

p,
input,
textarea,
select,
button {
  font-size: inherit;
}

input,
select,
textarea {
  font-size: inherit;
  color: $fontcolor;
  background-color: white;
  border: 2px solid #d1d5db;
  border-radius: 1.2rem;
  height: 2.5rem;
  padding: 0 1rem 0 1rem;
  transition: all 0.1s;
  &:focus {
    border: 3px solid #5c16c5;
    outline: #5c16c5;
  }
}

textarea {
  font-family: "Nunito Sans", sans-serif;
}

p {
  line-height: 1.4;
}

ul {
  list-style: none;
  padding: 0;

  li {
    list-style: none;
  }
}

/* VIOLET 700  & gray 100 */

.Progressbar {
}

.progressbar {
  background-color: #f5f5f5;
  height: 2rem;
  border-radius: 25px;
  width: 50%;
  max-width: 40rem;
  margin: 5rem auto 2.5rem auto;
  position: relative;
}

.progress {
  position: absolute;
  top: 0%;
  height: 100%;
  border-radius: 25px;

  width: 50%;
}

.percentage {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "../../variables.scss";

.rankingOverviewItem {
  display: grid;
  width: min(75%, 55rem);
  margin: 0 auto 1.5rem auto;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: 5.5rem 15% 1fr 10% 15%;
  justify-items: center;
  border: $border;
  border-radius: $borderRadius;
  height: 10rem;
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(255, 255, 255, 0.93),
  //     rgba(255, 255, 255, 1)
  //   ),
  //   url("https://howlongtobeat.com/games/82153_Demons_Souls_(2020).jpg");
  background-size: cover;
  background-position: 100% 18%;
  transition: 0.3s;
  background-repeat: no-repeat;

  &:hover {
    box-shadow: 0 1rem 2rem rgba(92, 22, 197, 0.5);
    transform: translateY(-10px);
    cursor: pointer;
  }

  h2 {
    font-size: 3.5rem;
    color: $colorMain;
  }

  img {
    height: 8rem;
    max-width: 100%;
    object-fit: contain;
    border-radius: $borderRadius;
  }
}

.Fieldset {
}

fieldset {
  border-color: #2222;
  border-radius: 12px;
  margin: 0 auto 2.5rem auto;
  padding: 2% 3%;
  background-color: rgba(255, 255, 255, 0.7);
}

legend {
  color: white;
  background-color: #5c16c5;
  min-width: 10%;
  text-align: center;
  padding: 0.5rem;
  border-radius: 5px;
}

@import "../../variables.scss";

.navbarContainer {
  height: 8vh;
  background-color: black;
  background: $gradientBackground;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  gap: 1.5rem;
}

.Navbar {
  .linksContainer,
  .userLinksContainer {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 33%;

    .links,
    p {
      color: white;
      font-size: 1.3rem;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .userLinksContainer {
    justify-content: flex-end;
  }

  .testLogo,
  .testLogo1 {
    color: white;
    font-weight: bold;
    margin-right: 1px;
    font-size: 2rem;
  }

  .testLogo1 {
    font-weight: 100;
  }
}

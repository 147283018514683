.CommunityRatings {
  height: 55%;
  width: 100%;
  overflow-x: auto;

  h2 {
    text-align: center;
  }

  .textReviews {
    overflow-y: auto;
    height: 100%;

    fieldset {
      margin-bottom: 1rem;
    }
  }

  .userRating {
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }
}
